import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

export default function initFancybox() {
	bindDefaultFancyboxInstances();
	bindTriggerElementsToFancybox();
}

function bindDefaultFancyboxInstances() {
	Fancybox.bind( '[data-fancybox]', {} );
}

function bindTriggerElementsToFancybox() {
	const triggerElement = document.querySelector( '.fancybox-trigger' );
	if ( ! triggerElement ) {
		return;
	}
	const { target } = triggerElement.dataset;

	triggerElement.addEventListener( 'click', () => {
		Fancybox.fromNodes(
			Array.from( document.querySelectorAll( `[data-fancybox="${target}"]` ) ),
		);
	} );
}
