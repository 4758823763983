// Import Bootstrap components
import "bootstrap/js/dist/base-component";
import "bootstrap/js/dist/alert";
import "bootstrap/js/dist/button";
import "bootstrap/js/dist/carousel";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/offcanvas";
import Popover from "bootstrap/js/dist/popover";
import "bootstrap/js/dist/scrollspy";
import "bootstrap/js/dist/tab";
import "bootstrap/js/dist/toast";
import "bootstrap/js/dist/tooltip";

// Custom
import InitGsap from './InitGsap';
import initFancybox from './fancybox';

InitGsap();
initFancybox();


// Initialize Popovers
const popoverTriggerList = Array.from(document.querySelectorAll('[data-bs-toggle="popover"]'));
const popoverList = popoverTriggerList.map(popoverTriggerEl => new Popover(popoverTriggerEl));

// Function to close all popovers
function closeAllPopovers() {
  popoverList.forEach(popover => popover.hide());
}

// Add event listener to close popovers when clicking outside
document.addEventListener('click', (event) => {
  const target = event.target;
  const isClickInsidePopoverTrigger = popoverTriggerList.some(trigger => trigger.contains(target));

  if (!isClickInsidePopoverTrigger) {
    closeAllPopovers();
  }
});

// Add event listener to close previously opened popover when a new popover is triggered
popoverTriggerList.forEach(trigger => {
  trigger.addEventListener('click', (event) => {
    const currentPopover = popoverList.find(popover => popover._element === event.currentTarget);

    // Close other popovers if they are open
    popoverList.forEach(popover => {
      if (popover !== currentPopover && popover._element.hasAttribute('aria-describedby')) {
        popover.hide();
      }
    });

    // Prevent event propagation to ensure the click outside detection works
    event.stopPropagation();
  });
});


jQuery(document).bind('gform_page_loaded', function(event, form_id, current_page) {
	// Only check page 5
	if (current_page == 5) {
		// Set max number of checkboxes and grab the boxes for this page
		var maxSelections = 5;
		var $checkboxes = jQuery('#gform_page_' + form_id + '_' + current_page + ' input[type="checkbox"]');

		console.log($checkboxes);
		// Bind to change event (both clicks and checking by keyboard)
		$checkboxes.on('change', function() {
			// How many are currently checked?
			var checkedCount = $checkboxes.filter(':checked').length;

			// If more than max uncheck the box... maybe could change to never checking in the first place but this works fine.
			if (checkedCount > maxSelections) {
				this.checked = false;
				// Just show an alert for now, maybe do something else.
				alert('You can only select ' + maxSelections + ' options.');
			}
		});
	}
});
