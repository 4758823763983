import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';



document.addEventListener('DOMContentLoaded', () => {

    // Fade up elements animation
    const slowfadeUpElements = document.querySelectorAll('.anmi-slowfadeup');
    slowfadeUpElements.forEach((element, index) => {
        gsap.from(element, {
            scrollTrigger: {
                trigger: element,
                start: 'top 80%',
                toggleActions: 'play none none none',
            },
            duration: 1.25,
            opacity: 0,
            y: 100,
            ease: 'power2.out',
            stagger: 0.15,
            delay: index * 0.25
        });
    });

	const isMobile = window.innerWidth <= 768; // Adjust the width according to your definition of mobile

	if (!isMobile) {
    const fadeUpElements = document.querySelectorAll('.anmi-fadeup');
    fadeUpElements.forEach((element, index) => {
        gsap.from(element, {
            scrollTrigger: {
                trigger: element,
                start: 'top 80%',
                toggleActions: 'play none none none',
            },
            duration: 1,
            opacity: 0,
            y: 50,
            ease: 'power2.out',
            stagger: 0.15,
            delay: index * 0.5
        });
    });
}
if (!isMobile) {
	    // Fade up elements animation
		const boxfadeUpElements = document.querySelectorAll('.box-fadeup');
		boxfadeUpElements.forEach((element, index) => {
			gsap.from(element, {
				scrollTrigger: {
					trigger: element,
					start: 'top-=100 80%',
					toggleActions: 'play none none none',
				},
				duration: 1,
				opacity: 0,
				y: 50,
				ease: 'power2.out',
				stagger: 0.15,
				delay: index * 0.15
			});
		});
}
    // Fade left elements animation
    const fadeLeftElements = document.querySelectorAll('.anmi-fadeleft');
    fadeLeftElements.forEach((element, index) => {
        gsap.from(element, {
            scrollTrigger: {
                trigger: element,
                start: 'top 80%',
                toggleActions: 'play none none none',
            },
            duration: 1,
            opacity: 0,
            x: -50,
            ease: 'power2.out',
            stagger: 0.15,
            delay: index * 0.15
        });
    });

    // Fade bottom elements animation
    const fadeBottomElements = document.querySelectorAll('.anmi-fadebottom');
    fadeBottomElements.forEach((element, index) => {
        gsap.from(element, {
            scrollTrigger: {
                trigger: element,
                start: 'top 80%',
                toggleActions: 'play none none none',
            },
            duration: 1,
            opacity: 0,
            y: 50,
            ease: 'power2.out',
            stagger: 0.15,
            delay: index * 0.15
        });
    });

    // Parrablock scroll animations
    gsap.fromTo('.para-100200',
        { y: -100 },
        {
            y: 200,
            scrollTrigger: {
                trigger: '.parrablock',
                start: 'top bottom',
                end: 'bottom top',
                scrub: true,
            }
        }
    );

    gsap.fromTo('.para100-100',
        { y: 100 },
        {
            y: -100,
            scrollTrigger: {
                trigger: '.parrablock',
                start: 'top bottom',
                end: 'bottom top',
                scrub: true,
            }
        }
    );
});


document.addEventListener('DOMContentLoaded', () => {
    gsap.registerPlugin(ScrollTrigger);

    // Animate the mask rectangles to reveal paths in reverse and staggered
    gsap.fromTo(
        "#maskRect1, #maskRect2, #maskRect3, #maskRect4",  // All mask rectangles
        { attr: { width: "100%" } },  // Start with the mask revealing the paths (width: 0%)
        {
            attr: { width: "0%" },  // Animate to cover the paths (width: 100%)
            duration: 4,  // Duration of the animation
            ease: "power2.out",  // Easing function for smoothness
            stagger: 0.05,  // Stagger each path reveal by 0.3s
            scrollTrigger: {
                trigger: '.heroriverpattern',  // The container that triggers the animation
                start: 'top 80%',  // Trigger when the top of the container hits 80% of the viewport
                toggleActions: 'play reverse play reverse'  // Only play the animation once
            },
        }
    );
});



export default function initInitGsap() {
	gsap.registerPlugin(ScrollTrigger);
}
